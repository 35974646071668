import React from "react";

export const useIsClient: () => [boolean, string] = () => {
  const [isClient, setIsClient] = React.useState(false);
  const key = isClient ? "client" : "server";

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return [isClient, key];
};
