// public
import React from "react";
import styled from "styled-components";

// local
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Logo from "../components/Logo";
import { useConfigCookie } from "../utils/useCookie";
import { Checkbox, Select } from "../components/Input";
import Nav from "../components/Nav";
import { useIsClient } from "../utils/useIsClient";

const Container = styled.div`
  margin: 2rem 0;
`;

const Label = styled.label`
  display: block;
  margin: 0.5rem 0;
`;

const InnerLabel = styled.span`
  display: inline-block;
  text-align: right;
  margin-right: 1rem;
  width: calc(50% - 0.5rem);
`;

const fontOptions: Record<string, string> = {
  "Arial,Helvetica,sans-serif": "Arial",
  "'Arial Black',Gadget,sans-serif": "Arial Black",
  "'Palatino Linotype','Book Antiqua',Palatino,serif": "Book Antiqua",
  "'Comic Sans MS',cursive,sans-serif": "Comic Sans",
  "'Lucida Console',Monaco": "Console",
  "'Courier New',Courier,monospace": "Courier New",
  "Impact,Charcoal,sans-serif": "Impact",
  "'Lucida Sans Unicode','Lucida Grande',sans-serif": "Lucida Sans Unicode",
  "'Lucida Console',Monaca,monospace": "Lucida Console",
  "'MV Boli',cursive,sans-serif": "MV Boli",
  "Tahoma,Geneva,sans-serif": "Tahoma",
  "'Trebuchet MS',Helvetica,sans-serif": "Trebuchet MS",
  "'Times New Roman',Times,serif": "Times New Roman",
  "Verdana,Geneva,sans-serif": "Verdana",
};

const fontSizeOptions: number[] = [8, 10, 12, 14, 16, 18, 20, 24, 36, 48];

const colorOptions: string[] = [
  "Aqua",
  "Black",
  "Blue",
  "Cyan",
  "Green",
  "Gray",
  "Lime",
  "Magenta",
  "Maroon",
  "Navy",
  "Olive",
  "Orange",
  "Pink",
  "Purple",
  "Red",
  "Silver",
  "Teal",
  "Violet",
  "White",
  "Yellow",
];

const textWidthOptions: string[] = [
  "300px",
  "400px",
  "500px",
  "600px",
  "700px",
  "800px",
  "900px",
  "1000px",
  "1200px",
  "1800px",
  "2000px",
];

const borderOptions: string[] = ["none", "solid", "dotted"];

const linkStyleOptions: string[] = [
  "Textised page",
  "Full page",
  "Plain text (no links)",
];

const infoLocationOptions: string[] = ["Top", "Bottom"];

const strToBoolean = (str: string): boolean => {
  if (str.toLowerCase() === "true") return true;
  return false;
};

export default function Options(): JSX.Element | null {
  const [isClient, hydrationKey] = useIsClient();
  const [font, setFont] = useConfigCookie("Font", "Verdana,Geneva,sans-serif");
  const [fontSize, setFontSize] = useConfigCookie("Font size", 16);
  const [fontColor, setFontColor] = useConfigCookie("Font colour", "Black");
  const [backgroundColor, setBackgroundColor] = useConfigCookie(
    "Background colour",
    "White"
  );
  const [textWidth, setTextWidth] = useConfigCookie("Text width", "800px");
  const [border, setBorder] = useConfigCookie<string | undefined>("Border");
  const [linkStyle, setLinkStyle] = useConfigCookie("Link style", 0, parseInt);
  // const [ linkAppearance, setLinkAppearance ] = useConfigCookie('linkAppearance', 'Underlined')
  const [infoLocation, setInfoLocation] = useConfigCookie("Info", "Top");
  const [searchForm, setSearchForm] = useConfigCookie(
    "SearchForm",
    false,
    strToBoolean
  );
  const [skip, setSkip] = useConfigCookie("Skip", true, strToBoolean);
  const [stripNav, setStripNav] = useConfigCookie(
    "StripNav",
    false,
    strToBoolean
  );

  console.log("Options render: Font is:", font);

  if (!isClient)
    return (
      <Layout>
        <SEO />
        <Logo />
        <Nav />
      </Layout>
    );

  return (
    <Layout>
      <SEO />
      <Logo />
      <Nav />
      <Container key={hydrationKey}>
        <form>
          <Label htmlFor="fontOption">
            <InnerLabel>Font</InnerLabel>
            <Select
              name="fontOption"
              id="fontOption"
              value={font}
              onChange={(e): void => setFont(e.target.value)}
            >
              {Object.keys(fontOptions).map((key, i) => (
                <option key={`fontOption-${i}`} value={key}>
                  {fontOptions[key]}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="fontSizeOption">
            <InnerLabel>Font size</InnerLabel>
            <Select
              name="fontSizeOption"
              id="fontSizeOption"
              value={fontSize}
              onChange={(e): void => setFontSize(parseInt(e.target.value))}
            >
              {fontSizeOptions.map((size, i) => (
                <option key={`fontSizeOption-${i}`} value={size.toString()}>
                  {size}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="fontColorOption">
            <InnerLabel>Font colour</InnerLabel>
            <Select
              name="fontColorOption"
              id="fontColorOption"
              value={fontColor}
              onChange={(e): void => setFontColor(e.target.value)}
            >
              {colorOptions.map((color, i) => (
                <option key={`fontColorOption-${i}`} value={color}>
                  {color}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="backgroundColorOption">
            <InnerLabel>Background colour</InnerLabel>
            <Select
              name="backgroundColorOption"
              id="backgroundColorOption"
              value={backgroundColor}
              onChange={(e): void => setBackgroundColor(e.target.value)}
            >
              {colorOptions.map((color, i) => (
                <option key={`backgroundColorOption-${i}`} value={color}>
                  {color}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="textWidthOption">
            <InnerLabel>Text width</InnerLabel>
            <Select
              name="textWidthOption"
              id="textWidthOption"
              value={textWidth}
              onChange={(e): void => setTextWidth(e.target.value)}
            >
              {textWidthOptions.map((width, i) => (
                <option key={`textWidthOption-${i}`} value={width}>
                  {width}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="borderOption">
            <InnerLabel>Border</InnerLabel>
            <Select
              name="borderOption"
              id="borderOption"
              value={border}
              onChange={(e): void => setBorder(e.target.value)}
            >
              {borderOptions.map((lineType, i) => (
                <option key={`borderOption-${i}`} value={lineType}>
                  {lineType}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="linkStyle">
            <InnerLabel>Link destination</InnerLabel>
            <Select
              name="linkStyle"
              id="linkStyle"
              value={linkStyle}
              onChange={(e): void => setLinkStyle(parseInt(e.target.value))}
            >
              {linkStyleOptions.map((text, i) => (
                <option key={`linkStyleOption-${i}`} value={i}>
                  {text}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="infoLocation">
            <InnerLabel>Textise information</InnerLabel>
            <Select
              name="infoLocation"
              id="infoLocation"
              value={infoLocation}
              onChange={(e): void => setInfoLocation(e.target.value)}
            >
              {infoLocationOptions.map((text, i) => (
                <option key={`infoLocationOption-${i}`} value={text}>
                  {text}
                </option>
              ))}
            </Select>
          </Label>
          <Label htmlFor="searchFormOption">
            <InnerLabel>Show search form?</InnerLabel>
            <Checkbox
              id="searchFormOption"
              type="checkbox"
              name="searchForm"
              checked={searchForm}
              onChange={(e): void => setSearchForm(Boolean(e.target.checked))}
            />
          </Label>
          <Label htmlFor="skipOption">
            <InnerLabel>Skip to main content?</InnerLabel>
            <Checkbox
              id="skipOption"
              type="checkbox"
              name="skip"
              checked={skip}
              onChange={(e): void => setSkip(Boolean(e.target.checked))}
            />
          </Label>
          <Label htmlFor="stripNavOption">
            <InnerLabel>Strip navigation?</InnerLabel>
            <Checkbox
              id="stripNavOption"
              type="checkbox"
              name="stripNav"
              checked={stripNav}
              onChange={(e): void => setStripNav(Boolean(e.target.checked))}
            />
          </Label>
        </form>
      </Container>
    </Layout>
  );
}
