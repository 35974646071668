// public
import * as React from "react";
import Cookies from "js-cookie";

// An example classic cookie that we need to support and replicate:
//
// Font=Tahoma,Geneva,sans-serif&Font size=16&Font colour=Black&Background colour=White&Background image=none&Text width=800px&Border=none&Link style=0&Link appearance=Bold&Link Outline=solid&Info=Top&SearchForm=true&Skip=true&StripNav=false

type ParsedCookie = Record<string, any>;

const cookieName = "textiseOptions";

const getCookie = (): ParsedCookie => {
  const dough = Cookies.get(cookieName);
  if (!dough) return {};

  const entries = dough.split("&");

  const cookie = entries.reduce<ParsedCookie>((acc, entry) => {
    const [key, value] = entry.split(/=(.+)/);
    return { ...acc, [decodeURIComponent(key)]: decodeURIComponent(value) };
  }, {});

  return cookie;
};

const saveCookie = (cookie: ParsedCookie): void => {
  console.log("parsedCookie:", cookie);

  const dough = Object.keys(cookie)
    .map((key) => `${key}=${cookie[key]}`)
    .join("&");

  console.log("dough:", dough);

  Cookies.set(cookieName, dough, {
    expires: 31536000,
  });
};

export const useConfigCookie = <T = string>(
  key: string,
  initial?: T,
  parser: (rawValue: any) => any = decodeURIComponent
): [T, (value: T) => void] => {
  const data = getCookie();
  const parsedData = Object.prototype.hasOwnProperty.call(data, key)
    ? parser(data[key])
    : initial;

  console.log(
    "Initial value for",
    key,
    "is:",
    parsedData,
    "--",
    data[key],
    initial
  );

  const [item, setInnerValue] = React.useState<T>(parsedData);

  const setValue = (value: T): void => {
    console.log("Setting", key, "value to:", value);
    setInnerValue(value);
    const cookie = getCookie();
    cookie[key] = value;
    saveCookie(cookie);
  };

  return [item, setValue];
};
